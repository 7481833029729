import React from 'react'
import { Container } from 'react-bootstrap';


const Footer = () => {
  return (
    <div>
      {/* footer */}
      <footer>
        <Container  fluid className='copy-right-container' style={{background:'#CC0000'}}>
          <Container>
            <p className='copy-right mb-0 text-white'>
              © 2024 UMI . All rights reserved
            </p>
          </Container>
        </Container>
      </footer>
    </div>
  )
}

export default Footer