import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom"; // import ทำลิงค์ React Rounter
import { v4 } from "uuid";
import axios from "axios";
import moment from "moment";
import "moment/locale/th"; // Import Thai locale

// Set Thai locale globally
moment.locale("th");

/************************************ */
/************************************ */
/********************* Parameters */
const mediaUrl = process.env.REACT_APP_MEDIA_URL;
const apiKey = process.env.REACT_APP_API_KEY;
const apiUrl = process.env.REACT_APP_AWS_APP_API_URL;

/************************************ */
/************************************ */
/********************* Return UI */
const ListPromotion = () => {
  // State to hold the data
  const [redemptions, setRedemptions] = useState([]);

  const header = {
    "x-api-key": apiKey,
    "Content-Type": "application/json",
  };
  // console.log(header);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    axios({
      method: "GET",
      url: `${apiUrl}/public/redemptions`,
      headers: header,
    })
      .then((resp) => {
        // console.log(resp.data);
        setRedemptions(JSON.parse(resp.data.data));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div>
      {/* list banner */}
      <Container className="pb-5">
        {/************************* */}
        {/************** Table Data  */}
        {/************************* */}
        {redemptions.length < 0 ? <>Try to Load Data</> : <></>}

        {/************************* */}
        {/*********** List */}
        {/************************* */}
        {redemptions.map((item, index) => (
          <TableRowRedemptionDataItem key={v4()} item={item} index={index} />
        ))}
        {/************************* */}
        {/*********** List - end */}
        {/************************* */}

        {/* {JSON.stringify(redemptions)} */}
        {/* banner 1 */}
        {/* <div className="banner1 list-banner">
          <Link to="/redemptions-detail">
            <img
              src="/banner1.jpeg"
              className="list-banner-img"
              style={{ width: "70%" }}
              alt="list banner promotion"
            />
          </Link>
        </div>
        <div className="banner2 list-banner">
          <Link to="/redemptions-detail">
            <img
              src="/banner2.jpeg"
              className="list-banner-img"
              style={{ width: "70%" }}
              alt="list banner promotion"
            />
          </Link>
        </div>
        <div className="banner3 list-banner">
          <Link to="/redemptions-detail">
            <img
              src="/banner3.jpeg"
              className="list-banner-img"
              style={{ width: "70%" }}
              alt="list banner promotion"
            />
          </Link>
        </div>
        <div className="banner4 list-banner">
          <Link to="/redemptions-detail">
            <img
              src="/banner4.png"
              className="list-banner-img"
              style={{ width: "70%" }}
              alt="list banner promotion"
            />
          </Link>
        </div>
        <div className="banner5 list-banner">
          <Link to="/redemptions-detail">
            <img
              src="/banner5.png"
              className="list-banner-img"
              style={{ width: "70%" }}
              alt="list banner promotion"
            />
          </Link>
        </div>
         */}
      </Container>
    </div>
  );
};

export default ListPromotion;

/******************************** */
/******************************** */
/************** inline function include */
/************** Result Row Item of Redemptions List */
const TableRowRedemptionDataItem = ({ item, index }) => {
  //

  return (
    <div className="banner2 list-banner" key={`itemno_${index}`}>
      {item.proThumb && (
        <Link to={`/redemptions-detail/${item.promotionCode}`}>
          <img
            src={`${mediaUrl}${item.proThumb}`}
            className="list-banner-img"
            style={{ width: "70%" }}
            alt="list banner promotion"
          />
        </Link>
      )}
    </div>
  );
};
