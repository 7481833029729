import React, { useState, useEffect } from 'react';


const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

// เชคเงื่อนไขถ้าเลื่อน scroll ลงมา 300px ให้แสดงปุ่ม back to top   
  const toggleVisibility = () => {
    if (window.scrollY > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

//เลื่อนแบบสมูธ
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <div className="back-to-top">
      {isVisible && (
        <button onClick={scrollToTop} className="back-to-top-button fs-5 fw-semibold">
          ^
        </button>
      )}
    </div>
  );
};

export default BackToTop;
