import React from 'react'
import { Container } from 'react-bootstrap';
import {Link} from "react-router-dom"; // import ทำลิงค์ React Rounter

function Header() {
  return (
    <div>
      <div className="border-red"></div>
      {/* banner hero */}
      <Container fluid className='px-0'>
        <div className="banner-hero fade-in-up"> 
          <div className="banner-hero">
            <Link to="/">
              <img src="/header_banner.png" className="logo-banner" style={{ width: "20%" }} alt="logo"/>
            </Link>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Header