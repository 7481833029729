import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react'
import { Container } from 'react-bootstrap';

// import component
import Header from './view/Header';
import Footer from './view/Footer';
import ListPromotion from './view/ListPromotion';
import BackToTop from './view/BackToTop';



const App = () => {
  return (
    <div>

      <Header/>

      {/* head title */}
      <Container fluid>
        <div className="head-title-box">
          <p className="head-title">
            ตรวจสอบโปรโมชั่นวันนี้ที่<br></br>
            <span className='head-title-big'>ท็อปส์ และท็อปส์ ฟู้ด ฮอลล์</span><br></br>
            (ยกเว้นท็อปส์ เดลี่)
          </p>
        </div>
      </Container>
      {/* end */}

      <ListPromotion/>
      <Footer/>
      <BackToTop/>

    </div>
  );
}

export default App;
