import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";

import axios from "axios";
import DOMPurify from "dompurify";

// import component
import Header from "../view/Header";
import BackToTop from "../view/BackToTop";

import { v4 } from "uuid";
import moment from "moment";
import "moment/locale/th"; // Import Thai locale

// Set Thai locale globally
moment.locale("th");

/************************************ */
/************************************ */
/********************* Parameters */

const apiKey = process.env.REACT_APP_API_KEY;
const apiUrl = process.env.REACT_APP_AWS_APP_API_URL;
const mediaUrl = process.env.REACT_APP_MEDIA_URL;

/************************************ */
/************************************ */
/********************* Return UI */

const RedemptionsDetail = () => {
  const pathName = window.location.pathname;
  const segments = pathName.split("/").filter((segment) => segment !== "");
  const promotionCode = segments[1] ? segments[1] : "";

  const [specialFirst, setSpecialFirst] = useState(""); // Use state
  const [specialSecond, setSpecialSecond] = useState(""); // Use state
  const [specialThird, setSpecialThird] = useState(""); // Use state

  const [displayProImage, setDisplayProImage] = useState(""); // Use state
  const [displayProThumb, setDisplayProthumb] = useState(""); // Use state
  const [promotionTitle, setPromotionTitle] = useState(""); //warning validation  *** required ***

  const [content, setContent] = useState(""); // Use state  *** required ***

  const [annotation, setAnnotation] = useState(""); //warning validation
  const [titleCondition, setTitleCondition] = useState(""); //warning validation
  const [condition, setCondition] = useState(""); // Use state >> CKEditor
  const [formattedDateRange, setDateRange] = useState(""); //start date   *** required ***

  const [timelineText, setTimelineText] = useState(""); //end date  *** required ***

  const [formEditData, setFormEditData] = useState([]);
  const [formIsLoad, setFormIsLoad] = useState(false);
  // const sanitizedHTML = DOMPurify.sanitize(promotionInfo);

  const params = {
    code: promotionCode,
  };

  const header = {
    "x-api-key": apiKey,
    "Content-Type": "application/json",
  };
  // console.log(header);

  useEffect(() => {
    const fetchData = async () => {
      axios({
        method: "GET",
        url: `${apiUrl}/public/redemptions/item`,
        headers: header,
        params: params,
      })
        .then((resp) => {
          // console.log(resp.data);
          var tempData = JSON.parse(resp.data.data);
          if (tempData.length > 0) {
            setFormEditData(tempData[0]);
            setSpecialFirst(tempData[0]?.premiumRedemption1);
            setSpecialSecond(tempData[0]?.premiumRedemption2);
            setSpecialThird(tempData[0]?.premiumRedemption3);
            setPromotionTitle(tempData[0]?.promotionTitle);
            setContent(tempData[0]?.promotionInfo);
            setAnnotation(tempData[0]?.promotionRemark);
            setTitleCondition(tempData[0]?.conditionTitle);
            setCondition(tempData[0]?.conditionInfo);
            setTimelineText(tempData[0]?.periodDateStr);
            setDisplayProImage(tempData[0]?.proImage);
            setDisplayProthumb(tempData[0]?.proThumb);

            var _startDate = tempData[0]?.periodStartDate;
            var _endDate = tempData[0]?.periodEndDate;

            // Parse start and end dates
            const start = moment(_startDate);
            const end = moment(_endDate);

            // Check if dates are valid
            const isStartValid = start.isValid();
            const isEndValid = end.isValid();

            let formattedDateRange = "";

            // Format dates if they are valid
            const formattedStartDate = isStartValid
              ? start.format("DD MMM YYYY")
              : "";
            const formattedEndDate = isEndValid
              ? end.format("DD MMM YYYY")
              : "";

            // Construct the formatted date range
            if (formattedStartDate && formattedEndDate) {
              formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;
            } else if (formattedStartDate) {
              formattedDateRange = formattedStartDate;
            } else if (formattedEndDate) {
              formattedDateRange = formattedEndDate;
            }

            setDateRange(formattedDateRange);

            fetchLog();
            setFormIsLoad(true);
          }
        })
        .catch((err) => {
          console.error(err);
          setFormIsLoad(false);
        });
    };

    if (formIsLoad === false) {
      fetchData();
    }

    // /***** Log */
    // const myHeaders = new Headers();
    // myHeaders.append("x-api-key", apiKey);
    // myHeaders.append("Content-Type", "application/json");

    // const raw = JSON.stringify({
    //   pc: promotionCode,
    //   lang: "th",
    // });

    // const requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: "follow",
    // };
    // // console.log(requestOptions);

    // // alert(promotionCode);
    // fetch(`${apiUrl}/public/logs`, requestOptions)
    //   .then((response) => response.json())
    //   .then((result) => {
    //     // alert(result);
    //     // console.log(result); //แสดงข้อมูลใน object
    //     // alert(promotionCode);
    //   })
    //   .catch((error) => console.error(error));
  }, []);

  const fetchLog = async () => {
    // fetch api login +++++++++++
    const myHeaders = new Headers();
    myHeaders.append("x-api-key", apiKey);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      pc: promotionCode,
      lang: "th",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // console.log(requestOptions);

    // alert(promotionCode);
    fetch(`${apiUrl}/public/logs`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // alert(result);
        // console.log(result); //แสดงข้อมูลใน object
        // alert(promotionCode);
      })
      .catch((error) => console.error(error));
    // console.log(inputs); // แสดง input user จาก state
  };
  return (
    <div>
      <Header />
      {/********************** Content */}
      <Container fluid>
        {formIsLoad === false ? (
          <>Loading...</>
        ) : (
          /********************** */
          /*************** Content */
          /********************** */
          <>
            {/* top content */}
            <Container fluid>
              <div className="head-title-box">
                <p className="head-title">
                  {specialFirst}
                  <br />
                  <span className="head-title-big">{specialSecond}</span>
                  <br />
                  {specialThird}
                </p>
              </div>
            </Container>
            {/* top content - end */}

            {/* Banner promotion */}
            <Container>
              {displayProImage && (
                <div className="banner1 banner-promotion-img">
                  <img
                    src={`${mediaUrl}${displayProImage}`}
                    className="list-banner-img rounded-0"
                    style={{ width: "70%" }}
                    alt={promotionTitle}
                  />
                </div>
              )}
            </Container>
            {/* Banner promotion - end */}

            {/* Detail promotion */}
            <Container>
              <div className="detail-promotion-card">
                <div
                  className="card card-width-custom rounded-0"
                  style={{ width: "70%" }}
                >
                  <div className="card-title">
                    <h4 className="card-title-text">{promotionTitle}</h4>
                  </div>
                  <div className="card-body">
                    {/* <div className="price-text">{promotionTitle}</div> */}

                    <div
                      className="card-text-detail"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(content),
                      }}
                    />
                  </div>
                </div>
              </div>
            </Container>
            {/* Detail promotion - end */}

            {/* Limit product */}
            <Container className="">
              <div className="limit-product-width">
                <div className="limit-product">
                  <p className="text-danger limit-product-text">{annotation}</p>
                </div>
              </div>
            </Container>

            {/* Condition */}
            <Container className="condition-box">
              <div className="condition-width">
                <table className="rights condition-table">
                  <tbody>
                    <tr>
                      <th>
                        <u>
                          <p className="condition-text mb-0">
                            {titleCondition}
                          </p>
                        </u>
                      </th>
                    </tr>
                    <tr>
                      <td>
                        <div
                          className=""
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(condition),
                          }}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Container>
            {/* Condition - end */}
          </>
          /********************** */
          /*************** Content - end */
          /********************** */
        )}
      </Container>
      {/********************** Content - end */}

      {/* Footer Date Promotion */}
      <footer>
        <Container
          fluid
          className="copy-right-container mt-4"
          style={{ background: "#CC0000" }}
        >
          <Container>
            <p className="copy-right mb-0 text-white">{formattedDateRange}</p>
          </Container>
        </Container>
      </footer>
      {/* end */}

      <BackToTop />
    </div>
  );
};

export default RedemptionsDetail;
