import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// import ทำลิงค์ React Rounter
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";

// import page
import RedemptionsDetail from "./page/RedemptionsDetail";

// สร้าง  path link page
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "redemptions-detail",
    element: <App />,
  },
  {
    path: "redemptions-detail/*",
    element: <RedemptionsDetail />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}>
      <App />
    </RouterProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
